import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "./Button";

export type IntegrationBoxProps = {
  image: any;
  integrationPartner: string;
  link: string;
  imageAlt: string;
  aboveTitle: string;
  text?: string;
  cta?: string;
};

const IntegrationBox = ({
  image,
  imageAlt,
  integrationPartner,
  aboveTitle,
  text,
  cta,
  link,
}: IntegrationBoxProps) => {
  return (
    <div className="flex h-full bg-accuRankerPurple-400 border border-white border-opacity-20 rounded-3xl mx-auto">
      <div className="flex flex-col p-12 justify-start w-full space-y-8">
        <div className="flex flex-row">
          {typeof image === "string" ? (
            <img src={image} className="w-14 mr-3" alt={imageAlt} />
          ) : (
            <GatsbyImage image={image} className="w-14" alt={imageAlt} />
          )}

          <div className="flex flex-col">
            <p className="m-0 text-white">{aboveTitle}</p>
            <p className="m-0 text-accuRankerOrange-500 text-3xl">
              {integrationPartner}
            </p>
          </div>
        </div>
        <p className="text-gray-400">{text}</p>
        <Button color="lightGray" link={link} className="max-w-max">
          {cta}
        </Button>
      </div>
    </div>
  );
};
export default IntegrationBox;
