import * as React from "react";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import Button from "@ui/Button";
import { BookDemoSmall } from "@ui/BookDemos";
import { StaticImage } from "gatsby-plugin-image";
import IntegrationBox from "@ui/IntegrationBox";
import { BigTestimonialCard } from "@ui/TestimonialCards";
import {
  IntegrationsDataQuery,
  StrapiCallToActionCard,
  StrapiIntegrationPage,
} from "../../graphqlTypes";
import { DeepNonNullable } from "ts-essentials";
import { graphql, useStaticQuery } from "gatsby";
import { ReactNode } from "react-markdown";
import styled from "styled-components";
import IntegrationLeft from "../images/Curves/IntegrationsLeft.svg";
import IntegrationsRight from "../images/Curves/IntegrationsRight.svg";
import { TAILWIND_DEFAULTS } from "@utils/GlobalConstants";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody } from "@utils/mdxUtils";
import { useRef } from "react";
import BookADemoModal from "@ui/Modals/BookADemoModal";

/** With Strapi Components' we lose type-safety unfortenately */
// type Column = Array<
//   | DeepNonNullable<StrapiIntegrationPage>
//   | DeepNonNullable<StrapiCallToActionCard>
// >;

type ColumnType = Array<
  | {
      strapi_component: "ui-components.integration-card";
      integration_card: DeepNonNullable<StrapiIntegrationPage>;
    }
  | {
      strapi_component: "ui-components.cta-card";
      call_to_action_card: DeepNonNullable<StrapiCallToActionCard>;
    }
>;

const GridWithLines = styled.div`
  @media (min-width: ${TAILWIND_DEFAULTS.screens.lg}) {
    &:before {
      content: "";
      background-image: url(${IntegrationLeft});
      background-repeat: repeat-y;
      position: absolute;
      height: 100%;
      width: 46px;
      left: 22%;
      z-index: -1;
    }
    &:after {
      content: "";
      background-image: url(${IntegrationsRight});
      background-repeat: repeat-y;
      position: absolute;
      height: 100%;
      width: 46px;
      right: 22%;
      z-index: -1;
    }
  }
`;

const IntegrationsPage = () => {
  const data = useStaticQuery<DeepNonNullable<IntegrationsDataQuery>>(graphql`
    query IntegrationsData {
      strapiIntegrations {
        pageTitle
        childStrapiIntegrationsTitle {
          childMdx {
            body
          }
        }
        ctaButtonText
        Column
        customer_case {
          companyLogo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 50, placeholder: BLURRED, layout: FIXED)
              }
            }
          }
          title
          slug
          excerpt1
          excerpt2
          companyPersonName
          companyPersonTitle
        }
      }
    }
  `);

  const {
    Column,
    ctaButtonText,
    pageTitle,
    childStrapiIntegrationsTitle: title,
    customer_case,
  } = data.strapiIntegrations;

  const ColumnData = Column as ColumnType; // To have type-safety

  const components: ReactNode[] = [];

  const modalBookADemoRef = useRef();

  for (let i = 0; i < ColumnData.length; i++) {
    const columnData = ColumnData[i];
    const { strapi_component } = columnData;

    if (strapi_component === "ui-components.integration-card") {
      const {
        integration_card: {
          cardButtonText,
          cardTitle,
          cardDescription,
          cardIcon,
          cardAboveTitle,
          link,
        },
      } = columnData;
      components.push(
        <div className="col-span-2 lg:col-span-1">
          <IntegrationBox
            image={cardIcon.url}
            imageAlt={cardTitle}
            aboveTitle={cardAboveTitle}
            integrationPartner={cardTitle}
            cta={cardButtonText}
            key={cardTitle}
            link={link}
            text={cardDescription}
          />
        </div>
      );
    } else if (strapi_component === "ui-components.cta-card") {
      const {
        call_to_action_card: { CTA_text, Full_name, Job_title, Image },
      } = columnData;
      components.push(
        <div className="col-span-2 lg:col-span-1 h-full">
          <div className="mx-auto h-full">
            <BookDemoSmall
              cta={CTA_text}
              image={Image.url}
              jobTitle={Job_title}
              name={Full_name}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block mt-10">
        <div className="flex flex-col items-start md:items-center text-center md:w-full bg-accuRankerPurple-900">
          <div
            id="title"
            className="text-left md:text-center text-white text-2xl md:text-5xl font-medium"
          >
            <MDXRenderer>{mdxBody(title)}</MDXRenderer>
          </div>
          <div className="mt-10">
            <Button
              color="orange"
              onClick={() => modalBookADemoRef.current.openModal()}
            >
              {ctaButtonText}
            </Button>
          </div>
        </div>
      </div>
      {/* Integrations Waterfall */}
      <div className="div-block mt-28 md:mt-10 mb-8">
        <GridWithLines className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 md:gap-x-12 md:gap-6 relative">
          {components}
        </GridWithLines>
        {/* CTA Banner */}
        <div className="max-w-full flex flex-col justify-center items-center my-10 lg:-my-2 relative">
          <StaticImage
            alt="CTA-curved-images"
            imgClassName="top-0 w-16"
            className="hidden lg:block w-16"
            style={{
              width: "calc(57% + 6px)",
              zIndex: -1,
            }}
            src="../images/Curves/IntegrationCTAFinishNew.svg"
          />
          <BigTestimonialCard
            headline={customer_case.title}
            text={customer_case.excerpt1}
            image={customer_case.companyLogo}
            jobTitle={customer_case.companyPersonTitle}
            name={customer_case.companyPersonName}
            slug={customer_case.slug}
          >
            <p className="mt-4 md:mt-1 text-xl mb-4">
              {customer_case.excerpt2}
            </p>
          </BigTestimonialCard>
          <StaticImage
            alt="CTA-curved-image-finish"
            className="hidden lg:block"
            style={{
              width: "4.5%",
              zIndex: -1,
            }}
            src="../images/Curves/FinishingCurves.svg"
          />
        </div>
      </div>
      <BookADemoModal ref={modalBookADemoRef} />
      <Footer />
    </Layout>
  );
};

export default IntegrationsPage;
