import React from "react";
import Button from "./Button";
import { GatsbyImage } from "gatsby-plugin-image";
import LinkButton from "./LinkButton";

export type CommonProps = {
  /** Image of the banner */
  image: any;
  /** Testimonial text */
  text: string;
  /** Name of the person */
  name: string;
  /** Job title of the person */
  jobTitle: string;
};

export type BigTestimonialCardProps = {
  /** Headline */
  headline: string;
  /** What to display on the right of the testimonial */
  children: React.ReactNode;
  slug: string;
} & CommonProps;

export type SmallTestimonialCardProps = CommonProps & {
  imageDescription: string;
};

export type BigTestimonialImageCardProps = {
  /** Headline */
  headline: string;
  /** Client's logo */
  logo: any;
} & CommonProps;

export const BigTestimonialCard = ({
  image,
  headline,
  text,
  name,
  jobTitle,
  children,
  slug,
}: BigTestimonialCardProps) => {
  return (
    <div className="flex flex-col md:flex-row bg-white w-full rounded-lg">
      <div className="flex flex-col md:w-9/12 bg-accuRankerPurple-500 rounded-lg p-10">
        <div className="w-48">
          {typeof image === "string" ? (
            <img src={image} />
          ) : (
            <GatsbyImage
              alt={image.alternativeText}
              image={image.localFile.childImageSharp.gatsbyImageData}
            />
          )}
        </div>
        <p className="text-accuRankerOrange-500 text-xl mt-6">{headline}</p>
        <p className="text-gray-300 text-md mt-0">{text}</p>
        <p className="text-gray-200 text-sm font-semibold mb-0">{name}</p>
        <p className="text-gray-300 text-sm font-light mt-0 mb-0">{jobTitle}</p>
      </div>
      <div
        className="flex flex-col p-10 text-accuRankerPurple-500 gap-6"
        style={{ maxWidth: "350px" }}
      >
        {children}
      </div>
    </div>
  );
};

export const BigTestimonialImageCard = ({
  image,
  headline,
  text,
  name,
  jobTitle,
  logo,
}: BigTestimonialImageCardProps) => {
  return (
    <div className="flex flex-col md:flex-row bg-accuRankerPurple-500 max-w-max rounded-lg">
      <div
        className="hidden md:flex flex-col bg-accuRankerPurple-500 rounded-l-lg triangle-box-right"
        style={{
          width: "300px",
          background: `transparent url(${image}) no-repeat`,
        }}
      ></div>
      <div className="flex flex-col p-10 md:py-5" style={{ maxWidth: "550px" }}>
        <div className="mb-10">
          <p className="font-medium text-xl mb-1 text-white">{headline}</p>
          <p className="text-xs mt-1 text-gray-400 mb-4">{text}</p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <img src={logo} className="w-14" alt={jobTitle} />
          <div>
            <p className="text-white text-sm font-semibold mb-0">{name}</p>
            <p className="text-gray-500 text-sm font-light mt-0">{jobTitle}</p>
          </div>
          <Button className="max-w-max" color="orange">
            Read More
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SmallTestimonialCard = ({
  image,
  imageDescription,
  text,
  name,
  jobTitle,
}: SmallTestimonialCardProps) => {
  return (
    <div
      className="flex flex-col bg-white rounded-lg p-10"
      style={{ maxWidth: "550px" }}
    >
      <div className="w-48">
        <img src={image} alt={imageDescription || ""} />
      </div>
      <p className="text-accuRankerPurple-500 text-xl">{text}</p>
      <div className="flex flex-row items-center">
        <div>
          <p className="text-accuRankerPurple-500 text-sm font-semibold mb-0">
            {name}
          </p>
          <p className="text-gray-500 text-sm font-light mt-0">{jobTitle}</p>
        </div>
        <Button className="max-w-max ml-auto" color="lightPurple">
          Read More
        </Button>
      </div>
    </div>
  );
};
