import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import Portal from "../../utils/portal";
import ErrorModal, { InfoType } from "@ui/Modals/ErrorModal";

const BookADemoModal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);
  const [values, setValues] = useState<{ [key: string]: string }>({});
  const modalErrorRef = useRef();
  const modalSuccessRef = useRef();

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose(),
    };
  });

  const handleOpen = () => {
    setDisplay(true);
  };

  const handleClose = () => {
    setDisplay(false);
  };

  const handleInputChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitForm = async e => {
    e.preventDefault();

    const data = new FormData();
    data.append("name", values.name);
    data.append("title", values.title);
    data.append("company", values.company);
    data.append("website", values.website);
    data.append("phone", values.phone);
    data.append("email", values.email);

    const response = fetch("/api/actions/bookameeting/", {
      method: "post",
      body: data,
    }).then(response => {
      if (response.status >= 300) {
        modalErrorRef.current.openModal(InfoType.Error);
      } else {
        modalSuccessRef.current.openModal(InfoType.Success, handleClose);
      }
    });
  };

  if (display) {
    return (
      <Portal>
        <form onSubmit={submitForm}>
          <div
            className="fixed z-40 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              />

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                <div className="bg-accuRankerPurple-300 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="col-span-1 sm:col-span-2 text-white">
                      <p className="text-2xl">Book a demo</p>
                      <span>Please leave your contact details:</span>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-white mb-1"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300 shadow-sm"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-white mb-1"
                      >
                        Job title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300 shadow-sm"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium text-white mb-1"
                      >
                        Company
                      </label>
                      <input
                        type="text"
                        name="company"
                        id="company"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300 shadow-sm"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="website"
                        className="block text-sm font-medium text-white mb-1"
                      >
                        Website
                      </label>
                      <div className="flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          http://
                        </span>
                        <input
                          type="text"
                          name="website"
                          id="website"
                          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 shadow-sm"
                          placeholder="example.com"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-white mb-1"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300 shadow-sm"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-white mb-1"
                      >
                        Phone
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        id="phone"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-accuRankerPurple-400 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="transition-colors duration-300 mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-accuRankerOrange-500 text-base font-medium text-white hover:bg-accuRankerOrange-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accuRankerOrange-900 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Book Demo
                  </button>
                  <button
                    onClick={handleClose}
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <ErrorModal ref={modalErrorRef}>
          <p className="text-2xl">Error booking a demo</p>
          <p>Please make sure you fill all the fields.</p>
        </ErrorModal>
        <ErrorModal ref={modalSuccessRef}>
          <p className="text-2xl">Demo booked</p>
          <p>Thank you. We will get back to you shortly.</p>
        </ErrorModal>
      </Portal>
    );
  }

  return null;
});

export default BookADemoModal;
