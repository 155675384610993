import React, { useRef } from "react";
import styled from "styled-components";
import { ShadowForCurves } from "../styles/shared";
import Button from "./Button";
import BookADemoModal from "@ui/Modals/BookADemoModal";

type CommonProps = {
  /** The image to get displayed as a cover */
  image: any;
  /** Text for the CTA button */
  cta: string;
  /** Name of the assistant */
  name: string;
  /** Job title of the assistant */
  jobTitle: string;
};

const ArrowLeft = styled.div`
  margin-top: 10%;
  background-color: white;
  transform: rotate(45deg);
  border-radius: 10%;
  left: 45px;
  display: inline-table;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 0;
  /* As the md breakpoint in tailwind */
  @media (max-width: 768px) {
    display: none;
  }
`;

const ArrowRight = styled.div`
  /* margin-top: 10%; */
  background-color: white;
  transform: rotate(-45deg);
  border-radius: 10%;
  left: calc(100% + 12px);
  display: inline-table;
  width: 30px;
  height: 30px;
  position: relative;
  top: -40%;
  z-index: 0;
  /* As the md breakpoint in tailwind */
  @media (max-width: 768px) {
    display: none;
  }
`;

const PreviewImage = styled.div<{ image: any; width: string; height?: string }>`
  background: ${props => `url(${props.image}) no-repeat center center`};
  background-size: cover;

  width: ${props => `${props.width}`};
  height: ${props => `${props.height ? props.height : "auto"}`};

  /* @media (max-width: 640px) {
    width: 300px !important;
  } */
`;

const EmployeeTag = styled.div`
  position: absolute;
  left: 30px;
  bottom: 20px;
  z-index: 10;
  @media (max-width: 767px) {
    top: 180px;
  }
`;

export const BookDemoBig = ({
  image,
  text,
  cta,
  name,
  jobTitle,
}: CommonProps & {
  /** The Headline text */
  text: string;
}) => {
  const modalBookRef = useRef();

  return (
    <div
      className="flex flex-col md:flex-row bg-white rounded-3xl w-min p-1 relative"
      style={{
        ...ShadowForCurves,
        maxWidth: "800px",
      }}
    >
      <EmployeeTag>
        <p className="text-white text-lg m-0">{name}</p>
        <p className="text-gray-200 font-light m-0">{jobTitle}</p>
      </EmployeeTag>
      <PreviewImage
        className="rounded-3xl"
        image={image}
        width={"400px"}
        height={"250px"}
      />
      <div className="md:flex md:flex-row md:justify-around md:-ml-20">
        <ArrowLeft />
        <div className="flex flex-col bg-white items-center justify-between z-10 rounded-3xl m-0 p-5 py-8 md:w-9/12">
          <p className="text-accuRankerPurple-500 w-full text-2xl mb-6 font-normal">
            {text}
          </p>
          <Button
            // onClick={onClick}
            onClick={() => modalBookRef.current.openModal()}
            className="w-full whitespace-nowrap text-sm"
            color="orange"
          >
            {cta}
          </Button>
        </div>
      </div>
      <BookADemoModal ref={modalBookRef} />
    </div>
  );
};

export const BookDemoSmall = ({ image, cta, name, jobTitle }: CommonProps) => {
  const modalBookRef = useRef();

  return (
    <div className="flex flex-row bg-white h-full w-full overflow-hidden rounded-3xl p-1 relative m-auto">
      {/* Row Layout */}
      <div className="hidden md:flex flex-row w-full">
        <div className="flex flex-col w-full bg-white items-center z-10 rounded-3xl m-0 p-4 md:p-5 md:py-8 md:-mr-7">
          <img
            alt="book-demo-image"
            src={image}
            className="rounded-3xl mb-4 md:hidden"
          />
          <div
            className="mb-10 p-2 w-full relative"
            style={{ marginTop: "30%" }}
          >
            <div className="flex flex-col">
              <h2 className="text-accuRankerPurple-500 text-right m-0">
                {name}
              </h2>
              <p className="text-gray-400 text-sm font-light text-right m-0 whitespace-nowrap ">
                {jobTitle}
              </p>
            </div>
            <ArrowRight />
          </div>
          <Button
            onClick={() => modalBookRef.current.openModal()}
            className="w-full mt-auto md:whitespace-nowrap text-sm"
            color="orange"
          >
            {cta}
          </Button>
        </div>
        <PreviewImage
          width={"300px"}
          // height={"320px"}
          className="rounded-3xl"
          image={image}
        />
      </div>
      {/* Column Layout */}
      <div className="flex flex-col overflow-hidden w-full h-full md:hidden">
        <PreviewImage
          width={"100%"}
          height={"320px"}
          className="rounded-3xl"
          image={image}
        />
        <div
          className="flex flex-col p-6 items-center bg-white rounded-t-3xl"
          style={{ marginTop: "-50px" }}
        >
          <h2>{name}</h2>
          <p className="text-gray-400 text-sm font-light text-center m-0 whitespace-nowrap ">
            {jobTitle}
          </p>
          <Button
            onClick={() => modalBookRef.current.openModal()}
            className="w-full mt-auto md:whitespace-nowrap text-sm"
            color="orange"
          >
            {cta}
          </Button>
        </div>
      </div>
      <BookADemoModal ref={modalBookRef} />
    </div>
  );
};
